<template lang="pug">
div(:class="{'z-paginate': true, 'z-paginate-focused':isFocused}")
    z-btn(:transparent="true" icon="chevron-double-left" class="z-paginate__btn" @click="clickLastFirst(false)")
    z-btn(:transparent="true" icon="chevron-left" class="z-paginate__btn" @click="clickNextPrev(false)")
    div(class="z-paginate__pages")
        div(v-show="!isFocused && pages!==0" class="z-paginate__pages__counter") {{modelValue}}/{{pages}}
        z-input(class="z-paginate__input"
                 type="number"
                 :dense="true"
                 @change="inputValueChange"
                 @focus="isFocused=true"
                 @blur="inputBlur"
                 v-model="inputValue")
    z-btn(:transparent="true" icon="chevron-right" class="z-paginate__btn" @click="clickNextPrev(true)")
    z-btn(:transparent="true" icon="chevron-double-right" class="z-paginate__btn" @click="clickLastFirst(true)")
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
    modelValue: {},
    pages: { default: 0 },
    reverse: { default: false, type: Boolean }
})

const emit = defineEmits( ['update:modelValue', 'change'] )

const isFocused = ref(false)
const inputValue = ref(null)

function inputBlur () {
    isFocused.value = false
    inputValue.value = null
}

function updateValue (value) {
    emit('update:modelValue', value)
    emit('change')
}

function inputValueChange () {
    // props.modelValue = inputValue.value
    updateValue(inputValue.value)
    // currentValueInput()
}

function currentValueInput () {
    // emit('update:modelValue', props.modelValue)
    // updateValue(  )
    // emit('change')
}

function clickLastFirst (direction) {
    if (props.reverse) {
        direction = !direction
    }
    if (direction) {
        toLastPage()
    } else {
        toFirstPage()
    }
}

function clickNextPrev (direction) {
    if (props.reverse) {
        direction = !direction
    }
    if (direction) {
        toNextPage()
    } else {
        toPrevPage()
    }
}

function toFirstPage () {
    updateValue(1)
    // props.modelValue.value = 1
    // currentValueInput()
}

function toLastPage () {
    updateValue(props.pages)
    // props.modelValue.value = props.pages
    // currentValueInput()
}

function toNextPage () {
    if (props.modelValue < props.pages){
        // props.modelValue.value = props.modelValue.value + 1;
        updateValue(props.modelValue + 1)
    }
    // currentValueInput()
}

function toPrevPage () {
    if (props.modelValue > 1) {
        // props.modelValue.value = props.modelValue.value - 1
        updateValue(props.modelValue - 1)
    }
    // this.currentValueInput()
}

// watch: {
//     value(newValue) {
//         this.currentValue = newValue;
//     }
// }
</script>

<style>
    .z-paginate {
        /*margin-bottom: var(--indent-size);*/
        width: 100%;
        position: relative;
        display: flex;
    }

    .z-paginate__btn{
        max-width: 2rem;
    }

    .z-paginate__input{
        max-width: 6rem;
        min-width: 6rem;
        appearance: none;
    }

    .z-paginate__input input{
        text-align: center;
        /*padding-bottom: 0;*/
    }

    .z-paginate__input input::-webkit-outer-spin-button,
    .z-paginate__input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .z-paginate__pages{
        display: flex;
        width: 6rem;
    }

    .z-paginate__pages__counter{
        flex: 1;
        height: 0;
        position: absolute;
        max-width: 6rem;
        min-width: 6rem;
        /* text-align: center; */
        top: 0.55rem;
        display: flex;
        justify-content: center;
    }

    .z-paginate__input{
       -moz-appearance:textfield;
    }
</style>
