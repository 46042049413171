import GoDB from 'godb'

const schema = {
    chat_messages: {
        uuid: { type: String, unique: true },
        room: String,
        user_id: Number,
        text: String,
        time: Number
    },

    forum_posts: {
        uuid: { type: String, unique: true },
        forum: String,
        user_id: Number,
        text: String,
        text_color: String,
        time: Number,
        url: String
    }
}

export const DB = new GoDB('arhichat-3', schema)

